import React from 'react'
import styles from './Social.module.css'

function Social(props) {
    return (
      <div className={`
        ${styles.Social}
        ${props.className || ''}
      `}>
        Hi! My name is Will, and welcome to the home of my baking experiments.
        <p>
          <img src="https://i.imgur.com/mihjncO.png" alt="Instagram"/>
          <div className={styles.igtext}>
            <a href="https://www.instagram.com/willbebaking/">@willbebaking</a>
          </div>
        </p>
      </div>
    )
}

export default Social 

export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "Will Be Baking",

  author: "Will",
  description: "Baking experiments",

  // The number of posts to a page on the site index.
  indexPageSize: 8,
}

import React from 'react'
import styles from './Bio.module.css'
import { getProfileImgURL } from '../utils/getProfileImgURL'
import Social from './Social';

function Bio(props) {
  let photoURL = getProfileImgURL();

  return (
    <div className={`
      ${styles.Bio}
      ${props.className || ''}
    `}>
      <img src={photoURL} alt="Me" />
        <Social />
    </div>
  )
}

export default Bio
